<template>
  <div
    v-if="response !== null"
    class="relative flex w-full justify-center bg-teal bg-cover"
  >
    <CloudinaryImg
      :public-id="response.background_image.public_id"
      :steps="[768, 1024, 1280, 1536]"
      class="absolute size-full"
    />
    <div class="z-20 flex-col">
      <div v-if="success !== true">
        <div class="flex justify-center">
          <div
            class="m-4 size-8 rounded-full bg-white sm:size-12 lg:m-6 lg:size-20"
          >
            <IconsEnvelope
              class="size-8 fill-teal p-1 sm:size-12 sm:p-2 lg:size-20 lg:p-4"
            />
          </div>
        </div>
        <div v-if="!showCaptcha">
          <Headline
            class="mb-2 text-center lg:mb-4"
            color="white"
            :title="response.title"
          />
        </div>
        <div v-if="!showCaptcha" class="flex justify-center">
          <FieldGenericInput
            v-model="newsletter.online_newsletter.email"
            class="lg:w-80"
            :class="{ 'outline-none ring ring-red-500': success === false }"
            :type="response?.email.type"
            :placeholder="response?.email.placeholder"
            :validation="string().required(messageRequired).email(messageEmail)"
          />
          <G7Button
            :pending="pending"
            :disabled="pending"
            class="ml-2 h-field px-2 lg:ml-4"
            :label="response?.action_button.label"
            @click="subscribe()"
          />
        </div>
        <div v-if="showCaptcha" class="flex flex-col items-center">
          <Captcha
            v-model="newsletter.captcha"
            class="mb-2 flex justify-center text-white lg:mb-4"
            :data="response.captcha"
          />
          <G7Button
            :pending="pending"
            :disabled="pending"
            class="h-field w-fit px-2"
            :label="'Absenden'"
            @click="subscribe()"
          />
        </div>
      </div>
      <div>
        <div v-if="success !== null" class="p-4 text-center text-white">
          <div class="mb-4 flex justify-center">
            <IconsCheckCircle
              v-if="success"
              class="h-6 fill-green-500 sm:h-8 lg:h-12"
            />
            <IconsCircleXmark v-else class="h-6 fill-red-500 sm:h-8 lg:h-12" />
          </div>
          <Headline color="white" class="mb-2 text-center" :title="title" />
          <p class="lg:text-lg" v-html="text" />
        </div>
        <p
          v-else
          class="my-2 text-center text-sm text-white lg:my-4 lg:text-2xl"
        >
          {{ response.note }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { string } from "yup";
import { defaultApiHeaders } from "~/composables/useGo7seasApi";
import { messageRequired, messageEmail } from "~~/composables/errorMessage";
import type { NewsletterSubscribeResponse } from "~~/types/response";

const { data: response } = await useGo7seasApi<NewsletterSubscribeResponse>(
  apiUrl(`/catalog/newsletter/registration/teaser`)
);
const newsletter = useNewsletter();
const showCaptcha = ref(false);
const pending = ref(false);
const title = ref("");
const text = ref("");
const success: Ref<null | boolean> = ref(null);
const { handleSubmit } = useForm();

const subscribe = handleSubmit(() => {
  if (showCaptcha.value === false) {
    return (showCaptcha.value = true);
  }
  pending.value = true;
  title.value = "";
  text.value = "";
  success.value = null;
  $fetch
    .raw(apiUrl(`/catalog/newsletter/registration/teaser`), {
      method: "POST",
      headers: defaultApiHeaders(),
      body: {
        online_newsletter: newsletter.online_newsletter,
        captcha: newsletter.captcha,
      },
    })
    .then((response) => {
      title.value = response._data.title;
      text.value = response._data.text;
      success.value = response._data.success;
    })
    .catch((e) => {
      if (e.response) {
        title.value = e.response._data?.title;
        text.value = e.response._data?.fieldErrors.map(
          (fieldError) => fieldError.message
        );
      } else {
        title.value = "Es ist ein Fehler aufgetreten.";
        text.value = "Bitte versuchen Sie es erneut.";
      }
      success.value = false;
    })
    .finally(() => {
      pending.value = false;
    });
});
</script>
