<template>
  <div class="flex space-x-2">
    <div class="flex flex-col">
      <span
        class="mt-1.5 cursor-pointer font-black leading-4"
        @click="updateModelValue('hochsee')"
      >
        Hochsee
      </span>
      <div
        class="mb-1.5 h-0.5 w-full origin-right scale-x-0 bg-white transition-transform duration-200"
        :class="{ 'scale-x-100': modelValue === 'hochsee' }"
      />
    </div>

    <div class="inline-flex cursor-pointer items-center">
      <div
        class="flex h-5 w-9 rounded-full bg-white"
        @click="
          updateModelValue(modelValue === 'hochsee' ? 'fluss' : 'hochsee')
        "
      >
        <div
          class="m-0.5 flex size-4 rounded-full bg-g7-blue transition-all duration-200"
          :class="{ 'translate-x-full': modelValue === 'fluss' }"
        ></div>
      </div>
    </div>

    <div class="flex flex-col">
      <span
        class="mt-1.5 cursor-pointer font-black leading-4"
        @click="updateModelValue('fluss')"
      >
        Fluss
      </span>
      <div
        class="mb-1.5 h-0.5 w-0 bg-white transition-all duration-200"
        :class="{ 'w-full': modelValue === 'fluss' }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const updateModelValue = (value: string) => {
  emit("update:modelValue", value);
};
</script>
