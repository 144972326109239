<template>
  <div class="w-full">
    <div
      class="mb-6 flex w-full flex-col items-center lg:flex-row lg:items-end"
    >
      <div
        class="flex w-full flex-col items-center py-2 text-g7-blue md:w-1/2 md:items-end md:py-0 lg:w-full lg:flex-row lg:space-x-4"
      >
        <div class="my-2 w-full md:my-0">
          <div
            class="flex justify-between bg-g7-blue px-2 font-bold uppercase text-white"
          >
            <FieldOceanRiverToggler
              v-model="cruisingArea"
              name="cruisingArea"
            />
            <div class="hidden md:flex">|</div>

            <div
              v-if="inclusiveFlightSelection"
              class="hidden items-center md:flex"
            >
              <input
                :id="`${inclusiveFlightSelection.label}-${idSuffix}`"
                class="size-4 accent-g7-blue"
                :checked="inclusiveFlight"
                name="offertype"
                type="radio"
                @click="
                  () => {
                    inclusiveFlight = !inclusiveFlight;
                  }
                "
              />
              <label
                :for="`${inclusiveFlightSelection.label}-${idSuffix}`"
                class="ml-2 cursor-pointer"
              >
                {{ inclusiveFlightSelection.label }}
              </label>
            </div>
          </div>
          <FieldComboboxByValue
            v-model="destination"
            class="md:min-w-80"
            :items="destinations?.options"
            :disabled="pending"
          />
        </div>

        <FieldComboboxByValue
          v-model="cruiseline"
          class="my-2 w-full md:my-0"
          :items="cruiselines?.options"
          :disabled="pending"
        />

        <FieldComboboxByValue
          v-model="ship"
          class="my-2 w-full md:my-0"
          :items="shipSelection?.options"
          :disabled="pending"
        />

        <FieldDatepickerRange
          v-model="date"
          placeholder="Zeitraum & Dauer"
          :durations="durations"
          class="my-2 w-full md:my-0"
          @set-duration="setDuration"
        />
      </div>

      <G7Button
        class="hidden h-field w-full px-2.5 !text-xl md:block md:w-1/2 md:!text-lg lg:ml-3 lg:w-auto"
        :label="searchOptions?.search_filter_data_fields.action_button.label"
        @click="search"
      />

      <div class="flex w-full space-x-4 md:hidden">
        <div
          v-if="inclusiveFlightSelection"
          class="flex w-full items-center bg-white px-4 font-bold uppercase"
        >
          <input
            :id="`${inclusiveFlightSelection.label}-${idSuffix}-sm`"
            v-model="inclusiveFlight"
            class="size-4 accent-g7-blue"
            :value="inclusiveFlightSelection.label"
            :checked="inclusiveFlightSelection.checked"
            name="offertype"
            :type="inclusiveFlightSelection.type"
          />
          <label
            :for="`${inclusiveFlightSelection.label}-${idSuffix}-sm`"
            class="ml-2 cursor-pointer"
          >
            {{ inclusiveFlightSelection.label }}
          </label>
        </div>
        <G7Button
          class="h-field w-full px-2.5 !text-xl md:w-1/2 md:!text-lg lg:ml-3 lg:w-auto"
          color="orange"
          :label="searchOptions?.search_filter_data_fields.action_button.label"
          @click="search"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CruiseFinderResponse } from "~~/types/response";
import type { DateRange } from "~~/types/form";
import type { CruiseFinder } from "~/types/search";

const props = defineProps<{
  links?: string;
  idSuffix?: string;
}>();

const pending = ref(false);
const cruisingArea = ref("hochsee");
const inclusiveFlight = ref(false);
const { data: searchOptions } = await useFetch<CruiseFinderResponse>(
  props.links ??
    apiUrl(`/catalog/homepages/go7seas/cruise-finder?cruiseregion=hochsee`)
);

const dataFields = searchOptions.value?.search_filter_data_fields;
const destinations = ref(dataFields?.cruise_region_selection);
const cruiselines = ref(dataFields?.cruise_line_selection);
const durations = ref(dataFields?.duration_range_selection);
const inclusiveFlightSelection = ref(
  dataFields?.offer_types_selection.selection[1]
);
const shipSelection = ref(dataFields?.ship_selection);

const destination = ref(destinations?.value?.value ?? "-");
const cruiseline = ref(cruiselines?.value?.value ?? "-");
const ship = ref(shipSelection?.value?.value ?? "-");
const duration = ref(durations?.value?.value ?? null);
const date: Ref<DateRange> = ref({
  start: dataFields?.arrival_date_field?.value ?? "",
  end: dataFields?.departure_date_field?.value ?? "",
});

function getSearchOptions(): Promise<CruiseFinderResponse> {
  return $fetch<CruiseFinderResponse>(
    apiUrl(
      `/catalog/homepages/go7seas/cruise-finder?cruising-area=${
        cruisingArea.value
      }&cruiseregion=${destination.value}&cruiseline=${
        cruiseline.value
      }&departure-date=${
        date.value.start ? date.value.start : "-"
      }&arrival-date=${date.value.end ? date.value.end : "-"}&duration=${
        duration.value ?? "-"
      }&ship=${ship.value}
      ${inclusiveFlight.value ? "&kombi=true" : ""}`
    ),
    { headers: defaultApiHeaders() }
  );
}

function search() {
  const region =
    destination.value === "-" ? cruisingArea.value : destination.value;
  const range =
    (date.value.start !== "" ? `/abreise/${date.value.start}` : "") +
    (date.value.end !== "" ? `/rueckkehr/${date.value.end}` : "") +
    (duration.value ? `/dauer/${duration.value}` : "");
  const flight = inclusiveFlight.value === true ? "/kombi/true" : "";

  const url = `/suche/reederei/${cruiseline.value}/region/${region}${range}/schiff/${ship.value}${flight}`;
  useRouter().push(url);
}

function setDuration(event) {
  if (event !== null) {
    duration.value = event.value;
  } else {
    duration.value = null;
  }
}

watch(
  [
    cruisingArea,
    cruiseline,
    destination,
    date,
    duration,
    ship,
    inclusiveFlight,
  ],
  async () => {
    pending.value = true;
    const searchOptions = await getSearchOptions();
    setSelections(searchOptions.search_filter_data_fields);
    setValues(searchOptions.search_filter_data_fields);
    pending.value = false;
  }
);

function setValues(dataFields: CruiseFinder) {
  if (dataFields.cruise_region_selection.value !== destination.value) {
    destination.value = dataFields?.cruise_region_selection.value;
  }

  if (dataFields.cruise_line_selection.value !== cruiseline.value) {
    cruiseline.value = dataFields?.cruise_line_selection.value;
  }

  if (dataFields.duration_range_selection.value !== duration.value) {
    duration.value = dataFields?.duration_range_selection.value;
  }

  if (dataFields.departure_date_field.value !== date.value.start) {
    date.value.start =
      dataFields.departure_date_field.value === "-"
        ? ""
        : dataFields?.departure_date_field.value;
  }

  if (dataFields.arrival_date_field.value !== date.value.end) {
    date.value.end =
      dataFields.departure_date_field.value === "-"
        ? ""
        : dataFields?.departure_date_field.value;
  }

  shipSelection.value = dataFields?.ship_selection;
  if (dataFields.ship_selection.value !== ship.value) {
    ship.value = dataFields.ship_selection.value;
  }

  if (dataFields.ship_selection.value !== ship.value) {
    ship.value = dataFields.ship_selection.value;
  }
}

function setSelections(dataFields: CruiseFinder) {
  destinations.value = dataFields.cruise_region_selection;
  cruiselines.value = dataFields.cruise_line_selection;
  durations.value = dataFields.duration_range_selection;
}
</script>
